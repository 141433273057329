import React from 'react';
import Container from '../components/Container';
import Roadmap from '../components/Roadmap';
import Collapsible from 'react-collapsible';

export default function Home() {
  return (
    <div id='home' className='homepage'>
      <Container
        image='./Morgan.gif'
        title="We're setting sail!"
        text="Ahoy Sailors! We're Parrots Of The
          Caribbean NFT! We are a community of 555 Parrots on a quest to
          assemble the most tightly knit crew in the NFT space for a grand
          quest. If you're ready for the adventure, let's sail!"
        left='true'
      ></Container>
      <Container
        image='./Lady.gif'
        title='the art'
        text='we have over 100 traits. each body trait is based on the colors of the flag of a caribbean country, and we have 10 legendaries - each of which is based on a prominent, cultural/histrical figure from the caribbean.'
        left='true'
      ></Container>
      <Container
        image='./theme.gif'
        title='the theme'
        text='The Caribbean is a region known for its sunshine, warmth, family and good vibes. We wanted to create our own island in the NFT space which would be like a slice of the Caribbean. Thus, Parrots Of The Caribbean was born. We have a motto: "One Crew On One Mission", and that mission is to make waves in the NFT space.'
        left='true'
      ></Container>


    </div>
  );
}
