import { MerkleTree } from 'merkletreejs';
import keccak256 from 'keccak256';
import { ethers } from 'ethers';
let whiteListAddresses = [
  '0x51B6AEB9bD546eCeC8a98283446Af9405ecCfEcf',
  '0xB40AF08abFd1F18032262520fD057BF8409E8234',
  '0x491E85b2d292cDD66477E8c70349eF2AC50a4Aa4',
  '0x13b977F260DB4a97EDbb875D7D31d5a2d243d37C',
  '0x9DA9a5bd7B8f48f7Cc40a7f4Ec211A70E76E654C',
  '0xaFd56Aa1412ceD162cE93bb2E4091207567F2171',
  '0x46C623A6E6d39f85d0C7C0f3d3450E65b7f83Ea2',
  '0xAd5f976081ff99d1152b7ffB2Be0775A27b7aA09',
  '0x5AC72F7F92EA6372D9E8a6131ac741163c97611F',
  '0x070abdFE500e8e9b92a06BD4b7c12fD934F244b6',
  '0xa7B99c55C7B6F31b3E2C19059F07697E0869e18D',
  '0x10cdaCd646dd468972529ad1C9848E14626D4Bbc',
  '0x971358d7C109dc66d646cfB9F20642D5f37E1B59',
  '0x1558A7E06CB93f68E4056bAa4757Ae6f19721BF5',
  '0x91EA1c57E1b0bf6cd33B85ef62a6BeD070093bF6',
  '0xcac8C59d187f0eaBa4bb37ee35D2f101566a77f2',
  '0xFC75d1b1c5FB59A6E6385ebc4F3e9f0e20373FcA',
  '0xbC517eb911928eCa393fa8be1703cBEF2Dca6285',
  '0xc23bb6bC4B751386746ba0866Cb500Fa642806A7',
  '0xbeD2ecB6b6B38D2D4bBE1bC94593Bc420647FD82',
  '0x71fCE78Fe6C7a9EB03C16a0b16338e9C72134fcE',
  '0x1AB0a037A7c6C07d75c79a7d55aC202E7C82E480',
  '0x85B8C4e652a69b21A2adC15dA2BDad5330E2Ad0c',
  '0x466AbBfb9AAb4C6dF6d3Cc03D6C63C43C5162048',
  '0x5eC1A8287368042c2978fEC6df7BF15b0FbC8b36',
  '0x3CdC3E0ca89b32FEA14e98DCc4d3C88Dc6Fd82cB',
  '0x7B17fF234a0C625a454ebBdBcbF2DcC34c341CD8',
  '0x4B6A535DfbBd7Bc4618F002Cd5441602F6004896',
  '0x8d8b48401EA73BA93bAd94BBe2524B4754597201',
  '0x61FEA7272f60e110b03C50B0DFe616E7bDBF64BD',
  '0xbF82d8835d716F5313cAe043f88c17126F362e2D',
  '0x4e618673caa5ae4cf9c0C91D403FBdB26C868bcb',
  '0xA415Ba552DB0EF948c7D3340C26Ac61772C0AA0f',
  '0x0f71b5afB389AF61Be54529898eEc917175353dB',
  '0x470ca68b32f362330Fb54F93E54Ac1Cc288e16EE',
  '0xEa66f33Fb201953316dcB3a96054B664eeD7c04b',
  '0x117ef17075C903ABB970856F3e6CF2dB5F07B7E7',
  '0x7D5444dFe09339C7a18395DDD37aAca38Bf3b9DF',
  '0xA6Ea80bDea3786E915CC951A4D0962BE884D5e11',
  '0x2305F895C5C317c8943eb09f14EACA102cBdEea2',
  '0xabD872dbcA2642deF526cD0682A3f40822226c86',
  '0xF4e1EDA388B58a9CE230E1714C66Ff0f77C18CdE',
  '0x57aa1B15323Bb23fe54d5d0BC2DaeDe11F8EFC22',
  '0x6aB7B619E11dD533a2b01c51D5D6Db37829b2706',
  '0x5e187AC276bDfF964a6e3b8314885f1c34082045',
  '0x3112898342C3D1fc12F745F6DeB5D762EACD7578',
  '0xbd745CD943fDD206Dd791f09482f3a71036622a7',
  '0x75DBAB57fAd27DF97470E812ECa3a305C8E51290',
  '0x386e8FF3A177410eB100E813417Ae2F1F3A024Cf',
  '0x643C6E20CD4D0c0bAfaE37C9C086f6ffe486c844',
  '0x857e72Fbf579Aa99e981e4CEC3C7F291298Fe875',
  '0x02B76e6E739d90F79ACEc1213DfcA47a830a60BE',
  '0x33707DFAA135b968135F6587D0aF285Ebe28624F',
  '0xa858Ea02845071dBe09b4b5C3BBea49529bEaadB',
  '0xa0be0C8C405C488d002C4d1D9186c87dcE27e102',
  '0x277f5499b1dB94e215f24db590334cd488DF7d44',
  '0xD6CfC8c53f94BB5aC2a9FCA0084a8da54eC1137e',
  '0xADc459a91d190Fb4577d107302AC0c6499fd25Ae',
  '0xfaF637662e0373A64a3cF2287e56000436Eac594',
  '0x824bF3217155D4ea3537A075350fF25fEefC1193',
  '0x8422b3649FbC8Df4651CB7711c0d10f75a460165',
  '0xF3998EcED96ae3cFe880aC9d2637B4118dA48E22',
  '0x4070192d0e9deD67d67b0f6C80ad9dd8c65b1d08',
  '0x5a11f9057B22d7Ce1417e6A0359965b414681819',
  '0x2Be2C0F632F69d3E5DAdB32b4DeC2689e1A77887',
  '0xCcF155063D4CE2D73dA36FC50b9A215763ebF52d',
  '0x55E00265F075f336f4Ca8760280031a67c5c9e45',
  '0x312e1A9Df5520777aea5d3267fc3F08D4f53bBB6',
  '0x2CC93944bFbF4Eef0a01FFE1853d0b34c89e3E6A',
  '0x653baa723C0D126674d6b853368ea010045A7F92',
  '0x11480FD2625784AE8840F75E612E979c0c3065A3',
  '0x5Bf0c8aBd4c19A5d8f35B8b79d71D58E0930Bc7c',
  '0x57cDDe74Dd2f239E2f83AA5C21F4FCBcD346a9e9',
  '0x91b6F978Ddfea98Ec9feF489e68B7f92B141aeF3',
  '0xb42B4558E8CC27f9ba298f756f344d2Ae24CcD44',
  '0xf8298234C70d64457D56139FEAbbE3BE7122a65D',
  '0xE2c720FA6a76489076ebDE449Fd06e98bF134e2d',
  '0x07Be1CDD0Fe5407bd943b698b06045E7758e708c',
  '0xF6b9452286EC9D03aA6ACBb74BF1194716996E20',
  '0x63a983A18f79E1fD2F8da57ef5A157561854be68',
  '0x3c8C097505f73c0B4409911722DC62D4Eecf8501',
  '0xa6A7b9470ad83c6250fb922507454B8Ba5da0ec0',
  '0x8f1450C06e273BB11fC76227fB3Bd79943b29E95',
  '0x710492Db800cd5c059E1B27b016EAB8f69db400D',
  '0x1E7cF5abBb02C6cab4D2c59bE8d5718579AD164D',
  '0xF83c2e39D1c4d55174A43122F23412545AD67A63',
  '0xB645443F5281C4110E6a61b2B080baca7C49018a',
  '0x61615945F28848f1735bFa596949da3dDdD8000D',
  '0x3Ea9aC509Bf9d2D848CB14762be9d01c6f058e64',
  '0x3D6f6043fFC09AD396535CdFAcb6e4bC47668e02',
  '0x9394cDB927986dbE186d9D85892DA22a06B853AE',
  '0x67B520DfEFbbFDdA8DdFDFA8a27165BCDfb9B5Eb',
  '0x71018C8015de79b641CFfD901a5122aA6c90b559',
  '0x7993d898403D82b692b7a794ecFaaF2Ed66e8792',
  '0x4D6dbb3ad626363A0f44E12B1C8639C403BE953b',
  '0x9D9CD8BDD2B00732EC55bac04ce2373dAb38f812',
  '0xbC8744370bCb6D5AbF5dE8B4086ecfBB4C5629C3',
  '0x9f89fa91440Dd9084182d22ce044d46695Ec5536',
  '0xbfce856beb4b44e650344202e4173e9e11518275',
  '0xE7902034B87e437247B7eccf9C7F676468840b6B',
  '0x3006A358b284eFc35d056a1c9caF5e5A7C67790E',
  '0xF6C09fCA3a5729E59Eb17e6e8db28cb9CD1C2055',
  '0x0B5be21B2B2c36B9d50DB382331Ed87faCf65D06',
  '0x3E457ED4B314CefFB512C9C66a0D2eE84353593e',
  '0x59348fb035d288c0287c69b66a4BD9bea0a7c7B6',
  '0xd63c2b1649a1f5e2bb7A4d09A66b74d0B23dfD07',
  '0x89F3C80f380cb02E89750889cA7b20eB87122F12',
  '0xc27D0B7348abBcbf88e1D3d894C45CF7635350Ac',
  '0x92B4860D345c5ecAFD13b34D2715C8cdac7384ff',
  '0xf3d7920FCC2Dbf9aA219F6a751028c06CF61133F',
  '0x21C6d1166652e72dd4Bb946E8D081f1a9Cb5d660',
  '0x139d9c6C8dbc8147E7EEF308067aAaD2598C6785',
  '0x49Ba7bdDb1d1037532c5f9499c687DacD45f1Fe5',
  '0xd054952345f497F7A9461a202E8f1284b885eE2F',
  '0x162c6dD35f1fc43d71E09c66cDf954813750B5A0',
  '0xA81dbB4C0A573C7C82D2419451FA0C8Bf67DB141',
  '0x54f1ed858Aa61b1e6355E8a212E8263904d55CF1',
  '0x12EFf9d7491C230A4f9EE2AaaB96E7fE07D7ec6E',
  '0xE46d41D4427804203BA04e5e2792B85F39a0f16b',
  '0x96718F910546A216538d1e12D57fcC00AfaC9A08',
  '0x439AE60bcDaA65905A96dAD9bcd47eA5B0936b05',
  '0x5d55881cD122853f6363ff31336d2935109dBE33',
  '0xA160E89117DAF8607E39Dd2d7A265b0089325af1',
  '0xafF8aEbE998DDd7F01455b82099BB891e082FCc7',
  '0x7c60Ab096A8E5e3A2FC0E84D7b9650fc58369FDF',
  '0xBe3580aB017137B827c7DCb4803Cb35ec112bF1A',
  '0x9CD5a3f2EbB62a1E6A6F97edb2042450661d61aa',
  '0x9162D4a6DcB8ba94A80e68c98A7777b855B1A4D1',
  '0x2A8070F313CBe95DaE30A80503c327CA830a15a5',
  '0xB629EeD4f6cB7832c83b1E8Fb763BF04f80CCd93',
  '0xF98DA3CC07028722547Bb795ce57D96bEbA936bd',
  '0x0173dc103fD72da50e1300dD72213b470c71390A',
  '0xBA7702D9CbE5E0cec397C609329C6c49ed630B1C',
  '0x7e0e555BE335E5e50aFB58d31Dd406d6E977fb57',
  '0x61C8A2363De04142Cc0C3B04935956E3f1B28f2e',
  '0xE9c4F87f4ad8c1AE2dc7Cada9CAdbcf5350202Ca',
  '0xCe99452883070b25EcFaf99b24d15eDCb4906584',
  '0x02b5D398394F5642973D2DA50800227f60B710c2',
  '0xc01e0C8B8c4946116eb63CAD93F46Dcfd4033061',
  '0xDf1De739bDe8Cc1901179201baAFD3f387f008C9',
  '0xaB89Acb0aa29b8A7B237B64589F782Be73d0AEB2',
  '0x6E3111AeDdAF8b7CBd71D33a0e981699dcB497ac',
  '0x34854cd1D25e1A24EB0b098DCF4a384670fb14Bd',
  '0x2f623b63EC0B567533034EDEC3d07837cFCC9feE',
  '0x2222E2Fcc49A13D1b5317214F8aee717864bCf9b',
  '0xb8a5f175D3868717C2826FA28Ab351870Ab6AB19',
  '0x53E1e12CCa78F3Fdb17F4d5175B88F62c2Ee4642',
  '0x4157d5F4a6454264c8C9CCE8e1C748bf5B201Ad0',
  '0x313d1C234002c370b322AaC9f95493926F83ffed',
  '0xA18a6f1559712dd2E7c7afFf7Ec7991c63675156',
  '0x4b8b594b5C1Da60094D7fb3f29487D21327c21e7',
  '0x658fE731d18C6910b73D87979C3Ed7d201e5CAcE',
  '0x8D9f95B34Ee97A1cA63b0AD8c559DDC55ae76957',
  '0x2B143cC08cdd999d92FDf44afeA5eBDC7296d90A',
  '0xd7963c15A9C987b4885569EE7dE027E9A366604D',
  '0x1DedDcfFf32EA23A7CC8F25F1004c34191587Ee8',
  '0xd16fef2012Fd8E90133EC8C735A827C0E4418aAb',
  '0xc886dB8b8CD260f5ee38Ba3d8f8E9324EE27EA33',
  '0xf9091Ba435A41F0D461d896cfea6F5E78fFB475e',
  '0x8853E7ca0314197C5343C798d11ccdF22c960290',
  '0xDB0B9f3D67Fd86727B2F9e5cb602FdA08460ad05',
  '0x382CF99c1D765899435796c72c3E39E061DB0a8f',
  '0x1c26257d096a92676Df4137C20a4B1580b169b68',
  '0xD3d38D9535895E1210779C98Cc81dfCbcB3E3baA',
  '0xCd0A87c465F768CF17A59E8b85691810a368e68A',
  '0xca2788C15F43E762350A7894DDaeE10c3903510D',
  '0xDff09251FC0dD86A8C48F8721e3E7F11C5bDB7A3',
  '0x93A3869aE9Abf012A3301EDF8ADd89c4c6cc6285',
  '0x21b4942a9B81F02F5f988E52A890eAeA57c08e32',
  '0x9367cF8CbF3c172d7c2471edbBF0F3699BBdB9A1',
  '0x92375C57ADbb9732CFe50DE3a4df9ecdD87E6cfd',
  '0xF9C43aAE12777357061a98F50689CCC9A7466f09',
  '0xa448693D275889EA22d185E812ECA19CdA24E4d4',
  '0x5ba2C367ebd0DE84FFCeAf8eCF187a2425C62968',
  '0x322b4D1Dea0213047Ff23Dd7687b6E0FCC78e546',
  '0x98ee4BD996Ee665206bDA37B6C53B07Ee73A0fA9',
  '0x8153858a85c1261672cefc2f78e2166d74818394',
  '0xf91304eaF4c1402Ad1255d20842CfC28801A9790',
  '0xca9d44E75bB08b5E8087FaC69f769e5FEa8bb293',
  '0x9afB9aE2678Dc93b5bBc83fA14ED27D7D291f57E',
  '0x61181606a66414f12f783017a1b1189D1467E3Af',
  '0x939e3643EBB382913560364d41cd930607e94eFE',
  '0x72BE6C9e534E347918E7282BB8FE03A8d40d024b',
  '0x7490959e122b2caa039ee76bAFb3b8e99E4AB181',
  '0x56E7817a56f7376e1D3203C32Fee3d624C0bDE07',
  '0x56e1b883a7ee8c948d014aF899E2710B8B89dE6d',
  '0x79593B91f2c6661A270CB61B059B7793235Bc544',
  '0x9f2b036f1158f505dD05d2753D813a84C76F4C8F',
  '0x8bfe553Ab794615B6009fbA5F077509A453DCBFd',
  '0xd11071FD3dfd4FbDF3EE8Fbe88D97894cf777375',
  '0xC717063F994e7F9a73dC7d292a7E28e30Bb8913C',
  '0xb555E396523F9ed0226De2045481015dad3D0c2b',
  '0xC3b029Ebc26eC6f08B560C4dc033dF261FD0f715',
  '0x8fB291e2ea91a1EC0B1AF73e554020F3489CeE0F',
  '0x93c60606CB51cF123731744FA70b5da1828dCc08',
  '0x65204a9D7Bd9301977010Aeb2cb320cF9aE9e899',
  '0xddDF6103204Ff9F24010C03BB86EF259E28364ae',
  '0xe929dDa45B6AB3bf58357603B988F437440E2136',
  '0x8269e14B9a3eD95DB483861788F3B4f006894a34',
  '0x7E5f079d65F257cCb204851594d821Ef5007FD33',
  '0xF55B413701c00B2c7A3D7E11c1B3D3BC84469E30',
  '0x80445399B0b66c95a8F1BAeCaEbE3e49A0Df649E',
  '0x53eAa9E9cC24F4C8C958a5cdb1f212e29ab1E4F0',
  '0x1EccA084Da586F5e67bf8fc222692503aD2D69f4',
  '0x822b9d322D8923f61239f2efAd13b10378cefF87',
  '0x71362355D840C5BB69f21cf7402b9cCD6b8dBA99',
  '0x68daE6788DeD4f09dc6818C6eD017E6Fe2433F05',
  '0xA3D5F82Dc69D8861e9461bD4eF8A3Ec9E24C6407',
  '0x83FD7410158A17e97d9753a54240521f8AFC973d',
  '0xa530747FCD8dE5e265504D3D7d0901bfA791045B',
  '0x88e1E5529Eb8A00b8EC336d131a4eF9784229C39',
  '0x20bDAf7432A516D6266dDf940a2A4A65C9051621',
  '0x2153C42e82d1DAEe2f5a9070ACABC78CC43EaabA',
  '0xa3b79B573D97Bcc29e3C498A61C256323ED0a990',
  '0xC8abd6eFdbA9E1a27741DC27E891100f2F2Aba3e',
  '0x3742AdF2000E1B284324c890c4Be2C78208FC9e2',
  '0x9F432A2964351a7830bD9EA10F65107677d73e04',
  '0x844be320476eEd55B9823Db1ad9c815598ED1C62',
  '0x0695FAEd525690b5EF1977FCC36077A1E6FF379c',
  '0xF76dAC53D9A2A1E87B169089EbA4A8DE7aF4156d',
  '0x89CE794D2B4079D202C9de6a62c71C11193BE9b5',
  '0x1591B6f0c7dc56b24B3527e44a9D5f3FAC0F3dC5',
  '0x461aDb309dBc497d22Bd1DD7267074C43e36C17E',
  '0x0Ea9508fb8a4c6d1c7c057F3c841f9B20dE6029a',
  '0x0CBD359352Ce5b2F040Ed5923e707899F082e22D',
  '0x4F235F4F8Cd3fabb9cE40B8FBf951667038b0a25',
  '0x6deC4a17475E5aD006aFffad28Ff2532aBEe2cFE',
  '0xA73cFCba568Eec05bA0f0eA6E8d1632562cfbC4F',
  '0xb83d79845ae31e0061d1361ab203237ad076c26e',
  '0xd3363277dE58f461a790796554a5Da483daCb319',
  '0x853119Ef5d03C6D4e5BA8E564B80B1A8BAD71E4C',
  '0xF75E9967686B994743a3031fCE0B842b938409EA',
  '0xb76e940Bf40620F410c15b3cB777B72e34Ad25c5',
  '0x40B9723a4aB6F5D263453d9c1410aB5D7B2E9b0b',
  '0xF06000dAA3c2280c5F79D3D3CA09c0790521C0db',
  '0x5bC88581Bf384f68328D33B480f66b18D2c5E5fe',
  '0x731aED3abB40f3fbF7451fa0B3cAE90aDC8DeFaD',
  '0x23206830471c151c799AC8bf15Ca8AFe5669ECCD',
  '0xeC83638dcF4321c0A7b76656355E7c945d7360c1',
  '0x30AdFd3fe38e44457Df471d4d84640E8Ea834796',
  '0x5987C5F1223E0D8364eD65df9ba96540daaAd8CE',
  '0x9f4A5AaB551B678eeB8F22197d6b1a61E07387a4',
  '0x7C626870d99Ba9483Ac7356A98Bc802e648425db',
  '0x01eBF4555eE8CB6AD175030ED9e31d38405BDaE8',
  '0xEf3984bf2DE454FB7c7ABb810f6900b51B5D2ed1',
  '0x12889a880c91989Ba198886F2afb235707B5d5ED',
  '0x5Ed25B69C5bC164aC5Bdd7D139C197A0e7e3101a',
  '0x89A992323cBd5E0a207825eCB278f5b5cFfAd4C3',
  '0xb0c8b8e68D71915FEc3d81066E0467D8a544614B',
  '0x80FB4816dFE1Caa0C80B9f3081A961E647287AC8',
  '0x70BD7fb88ABA01ECc124866cC824A50f5cab86b3',
  '0x152d06cDAa573Cb48562680D8A9d383B3EeD4b5F',
  '0x93632232144bcE21Ce87575e0d26639B33500951',
  '0x99e7aE0485C194c63B0Ca73e1c450AA7f76Ec8f8',
  '0x18126e9d643E0C8cCCDd632880181FCC34934355',
  '0x66937B501A25861F0731cC8C4F5D959f134F79A2',
  '0x419fD53f8c5c957Ae2c52A7df6904e986E59db62',
  '0x4b5a40370cb8F56621B66e5097Fc79a3F44B914A',
  '0x4a57bE05D29dd966F0739EfA3b47Cad9725eCDc1',
  '0x8Df2794f0730C6E09CD8B7c61c8ec755f23C6E95',
  '0x5C9169BF7c7858Adfd7C9EbBD03CEbA36F664037',
  '0x11ecB40BC4B42642e99c60960FD472687156740c',
  '0x31cC8F75d3ec1873Eff20d7636bB75490a9701f9',
  '0x2140D57C0958AaA7cCC27e04B7620f5Bc6903864',
  '0x23bfDa9e2C0C8B71E3F6a8103b4BBF7f6588b192',
  '0x75221f71544d53333Cc4569641A690f1f7d43593',
  '0x8fA413E2069E886FF043Bb79352C03036E522b52',
  '0x681831C37C503A6DBbF3c61771bC563cB4d2D0c8',
  '0xBFF4b749a2C93b6648A9523CD27458bc06990602',
  '0xd8e75A9eCadFD63c453995702e3a5D185755A4ec',
  '0xfAF693C6B4D5Fcd9A1f57FA106f70801Df831aCc',
  '0xF0Eb0e914C1305468D5F8706257F5A58e4916e0E',
  '0x425CEf3543E947B942b233193AE77AC83820b414',
  '0xA5D309F95939573eE80039761173200117832351',
  '0x3ba2F91F3f6299359DedFa508E0E910Fb68C8da6',
  '0x5C851F80b17bBb84D2a9b30291546ae2C6286626',
  '0x52a6117cD4D0CffEc2ED332e3557C1aCaa09E511',
  '0xd0Ef94346B8cB747597eBDeb736aC59523201747',
  '0x6fc2570540066689fa144A02017a4B776177b823',
  '0x3e1b8B638ABD905F71d76e72d1211B64482DAC15',
  '0xeB7C3790152B02A1b07704048561b0ab2c012BE9',
  '0xe133b688Efdaffcf93B4C17b08C3a4f965a05363',
  '0xe973B9fDC98586D0BE196fC5dA93e6D26CE9A899',
  '0x0D5D942f7620AD72016Ee69F00c2B6C4769A9108',
  '0xaB4c1D180AD252d0Af8453A2Fbd0b64e03b85F42',
  '0x373623ceE0403573306c8387ba827eCb9a164ABF',
  '0xd76907f41048F30367c9035C957f269fA17093BB',
  '0x0b97C6a5e0D30747C474c8d2baE4da4D88DdbBa4',
  '0x5389789985f4c795c56e706B7c283F8903e29e47',
  '0xb63ea4865cDfedF3A7bCa5Df5BD49F04D59ea348',
  '0xa294BC0613E9ACB9EF74ADeF07F2a0bcdC9471fB',
  '0x1BBdeED4C73f73093B7c3E52Bfbd71a55D17B3e8',
  '0xa671d8C5A0c0b423186A380b08E9507a5cF4F1D7',
  '0xc2d94CF3848541646731342f08b8138F63771aAd',
  '0x951cA015BCE7852Fc6389346ed6dCDD5DDF550d2',
  '0x6064E9d295B64E7064CDDC3A6EDd50B8F5839d50',
  '0x794603C1a0c4799b04Cd8F9406D2b201286CE63A',
  '0x637Fd60cc34883CAb05Ac8B3548c31265A0072a8',
  '0x78588659e5cD51aE6BF9EfB4059A5DE48d673FF3',
  '0x0a6362541867a7f342F6576D5Df3FC291866C19F',
  '0x25088BDA816AAa730da5eaBFC85FE49d153A6589',
  '0xa7786CfA7B5e53599e666D33a9d5E1D05b2688c3',
  '0x5808017688624140475420fbb6D2c767b39E73E3',
  '0xA3CE81Cdb711794F7E34dc7CB2E3083DA00B4A27',
  '0xa46F710E3e9ccBdD479D3D30Cf327C5fb2180d8D',
  '0xeb4E1D33651D2d0B18778d88D825f896966115a3',
  '0x3E2af1FC75a5fC0a84d69d326c7d3E1EF7534964',
  '0xC1eAE831c306667Ea3a65B05f993A6A7048475A2',
  '0xb3A25689c00b82EBf22B45aac83026bA7975b80B',
  '0x9d279a05261D0Dd07174ea6F0F553D86BB3e779b',
  '0x66862A351B6e6e8549E5e6BdDb98a3A4EB883F35',
  '0xeE97cbF18Fc41C068eb8AFE67025353346c5fA02',
  '0x8b50843321a4FB88e6aEF9F10859bBe9E60613E7',
  '0x7C38EA7B9ab7D30350b5D7830bC15Ef68E651556',
  '0x680cd1da557BAe0dC5BE6a45edcc6E97483dAB40',
  '0x31A0aeB7eF65b9f795652DA4Fc73C6aD7Bae244a',
  '0x391C57Af1b8607e6C96d5FB76fd0CEB8617fEe41',
  '0x66f158c507c0fe235Dcedae3A2A629D1b6dFBfF3',
  '0x78827309b3634b02207bd14e6c50B5e7dB647d42',
  '0x9918ccC83f453965E634eB0788f94729775554c0',
  '0x3743F1af0956e87a5B6f227e4Cb76F11e6f11d1d',
  '0x3157DeD4D5C3985d62AD5Bf9c49dC973AF7dC748',
  '0x9dB7bbB19f5Cfe7E4a4240F6948B4A5D17beE22B',
  '0xc84D8113f47f0F3535E22a185EAEDA73216169F3',
  '0xf817fd6db480207e70d4960fE3118aB9fea44548',
  '0xE80CCfE24c2223a4DbE7b987aF91Ecf62393D433',
  '0xf9D7104E79B6D17794615C750CDe57C567DfF3DA',
  '0xc1ec09524E4E8392706313043381Cc4Cd077604A',
  '0x6fefF91951bB5D3438c37f0Ad6f9cBb3900808Ff',
  '0x284A986679006e77BD1BB67216d1710fd66B88f9',
  '0x3fe19Ec033F1Fa5F29493cb1AdE2AbACe27Bf441',
  '0xc173E56c96BA3af606d75ED4f97A85FCeE107166',
  '0x3a09995d8b189825375973DC9E01bDD76f03Dc70',
  '0x322C9C5B0c7BA50B06702dBE051FD37c939f6570',
  '0xaAc72BB1f44572637e6F2CF3B464159A0531fdf3',
  '0xaFB2BdeCafeC778923cC9058c9642565B2999A29',
  '0x7C3c735CB14BA14C59C7fD320aD6b2ea3c2bD8C9',
  '0x74484D1402F44894ea19c446A9ADb3F1E9778751',
  '0xD2D84DD4844c88e6c17bA35a6DCD86664e2b777B',
  '0xa6390D340117b04eDE43fFdd94D5c390E3C23210',
  '0x8d97149978d47124f0f79CC35cD6cb56720eFe89',
  '0xc30775d3429139562afE17117b4957e3e0B1Fe5E',
  '0x2D09b091922809a2b0Ead12866211fB4389A256C',
  '0x36C8572D088cC37D34A5e5e4442311653315cdD4',
  '0xB34FD18e01a5A42216E461582Fbd1e31B671EAf2',
  '0x572E599D2B0225ca86C1677035BCc065f9aEefa7',
  '0x8CA65A3Ce90d31a88FAa747c695183C2aefe537f',
  '0xaB1DeC72E87b19c90a6f20B109745e56ab48e17a',
  '0x0e28083f4cBF8d4C32ab2B09339183D14acb9F55',
  '0x41907Cfd4AcFC1682868ABEA1B03c22DE1a68A72',
  '0xB8f6aB7B30CFf81d3B285a792b2917b35C885675',
  '0xbe85F5aDf3aFfFEa08a2529Bf992Ee96525Cfd2f',
  '0x235Dd204F87F3381521D1c7d7782d305adbFF015',
  '0x047791E8C262F4Cd2aEe8906B9C7B7eE52A4687c',
  '0xfb1b433A3cB9cf828041e497614Ae2d8b6d8e02D',
  '0xeB879368775253C58281288E47fDC4C39C674A39',
  '0xb67B362b89b83fa1Bea4545082968a0fCaD7Ce5f',
  '0xE6286CFFf85bee3982B45d643af54194c3c5D72a',
  '0x31fB347DDE53F3b04406aD77B00aAf7144AE9B93',
  '0x9D989B692c75Bb3FCD6d2b9429DFAe9D982Ff860',
  '0xCf7f51347E9420268375dF12C6BaD832df233146',
  '0x94f7754d11DBED04F66afd27c5C2C2EeB9F3C618',
  '0xA0Ce4dd3F73B11a42cacb6d2627956732179799E',
  '0xfC13092BED04946930e0F6fe1fCC8343E7cdae41',
  '0x695B0B6BEd177249cBfB5992590783F737823fB7',
  '0x1Fd502A324340454855eE0056DdF2Ef0da6644AE',
  '0x6AD59A5255232d74E9b594E1EB8F341D3fC2357b',
  '0x0D3Ef24cCd12827CcE51bcA43Cf9EfB78380429c',
  '0x240cE6219e66D1798456840b52ec67990f2fFC60',
  '0x3bE12f903ba629c0A87C5574CFEFF11b29782999',
  '0x1E26124376f529E6d5b3d5f7F79cc44B5Fc3543e',
  '0xCbD5738Fe8E57621C33ea7A98Fd2400342587941',
  '0x01f9B2b507db25f6868EAf1Ec99481f057B5Dfaf',
  '0x24D201C793b0F5FAC8821abC031D8999eFcc9615',
  '0x3BDA32b05264ee70B725e5d007Be19e886365f24',
  '0x8FBECDB339Ad0ba07E229269b53dAb7BEbEb1f30',
  '0x081D1A975Bf51Ec8e478D509fd2e45c0704e84dB',
  '0x88Dc68A1074703443775a98dE793650625465c5C',
  '0x65a08C12685D0CAfB103d4C8B6209AcED9CEC55B',
  '0x86123D57d987EE48C33EC3Fe2A5b0fB6a11a6dD4',
  '0xEF6c29bAC5b231Aaf11C694855DB8A37D4bED8ee',
  '0xA06E4c82923d3f56030bBf44F4bE8a5E2e386f1e',
  '0x14b5bA9e9Bb490eC60e7c5202b6c99797C1367Ed',
  '0x813303bf74009452935112522F8cb8e34bC88080',
  '0x4D840fEA8Da3ae3A823FE24F4B4DaaF11139B65B',
  '0x04DC1e988FF93baFA22599122bF6678576c631D1',
  '0xfC9f725A159BC430C616b2413EdCBfb544D41c50',
  '0x5022cF478c7124d508Ab4A304946Cb4Fa3d9C39e',
  '0x46AcAA899bFbD962a19DaAe136837e4f4c10FeB8',
  '0xd24D37dfF53Cf50faa81a832fe39B5eBcA24506d',
  '0xa93CF0e0682BEE6626a2A34e7e1D284a8b8E1E85',
  '0xE37314e82209b81dE691E75bdf24A9Bfb6f93427',
  '0x3A13646cf7Fac5a566d76B048deD85BAB2F1E075',
  '0x95ccD78C657da45597cC37f56749C9259a6E9303',
  '0xf4BF30Be1bfCC5455D8A48aE1B1412a09ea335c2',
  '0xc01377c5489f3e7FB90cfa28D9d0595c6E329603',
  '0xD7a363210b94fA549756035Ff277f60495F046D2',
  '0x3D460Ec0f0aDd1F53158B70E8aE8A03A0278975b',
  '0x2029723ab15B180Bf2A610642d93fAEC60E9B40C',
  '0xcB1fe1a42CF3Dc728ec349Ed46F2554893c2Fd96',
  '0xa496645dc650176A6649418086b4406187622799',
  '0x7A16138Cd3d762720864ef5b0b9DA411b0D47B2A',
  '0xD5F4Ab8061c1c80450a93d4ed145A689e02ecE91',
  '0x2f161c1ceBBcDc1A0C843Bd09a202E4BFc2C717D',
  '0x78E9F69edF35Bf133738B6d27D0D01ceB07B7414',
  '0xa7088c0943ced35351b9CC5B7FcfDE896d2f642c',
  '0x08AcE91217285e4EEEc5322974861A5420126989',
  '0x1fdDd055AB5B53B918a85615649ca7f83536BbdB',
  '0x501C1763e301b52927AF11d948C17437c55db0E0',
  '0x63Ca7A3F3c2984a286EB3be6afe011Ed6a5131df',
  '0x746044b022203DaD99284Ab110B795e54062A8F2',
  '0x5fb6D81B35986D8c7D394BdDc18fB444820b9591',
  '0xcCb3160704A7f2A4b2463b02c0de70B646940c94',
  '0x5bB822302e78c978F3d73CD7565Ad92240779CAE',
  '0x7B8BBe991Cde89179Ad5398B95B4ed450F8295e9',
  '0xCFB6eDc9AE1cCAAC742944e6E2F226722Fd22954',
  '0x778BEDD7Efb439b3C546026Cb7eC6A59002c4A28',
  '0x70e4FBcE45615cEB161d5F06e1815D27bec5F47a',
  '0xED8843D590db708C508569e5ea9bb27c90343307',
  '0x8fFBFb1A9796F42900a15E82CA239b395b167D3E',
  '0x0738E556557e9d48CA44417B5a2Cb81c2450c69e',
  '0x3cCbb20172B29ecdFC8ED00fF449CF47F115bD97',
  '0x2bdAaBDa8a3A5e2421981cF5bf01B486cdaebf58',
  '0xAA22C4e403c05303cdE2E247495ac885D20f94e6',
  '0x70bb87E6419C59650ae28B20cC9aa1e399b9016A',
  '0x9b7acE30bE57d07620f1D97476b826ca3972d59F',
  '0x3385ea05BD0cfAEaCf8D344531c13878F6de88aD',
  '0x7229EA0518b0613A8ff47fb12576820Ff298f479',
  '0x64ff8A32bd2b2746ed2A42Ce46eb1Bd74C59f70C',
  '0xd343c92012019B77AcA6290Ac5A85F55C98C94AF',
  '0x4439f1b2000C3baA207432387Ba635Ef6CA56aE0',
  '0xC0eCb3748C41d90F2641A3f8737D452800f4460C',
  '0x8F32B4b6b107B1aDA4B1c80b2EE8e025D7DB6B67',
  '0x6819Ea257A64aA4a4B68A1fbf84d198463765d57',
  '0x56f4bAFdA4995eB850b63AF1E64743f5d7f4CBaD',
  '0x5f683bd8E397e11858dAB751eca248E5B2afc522',
  '0x1f2285C2B764153EAaA3875721465EA5b4dD899E',
  '0xD486BF526740200FA87191DEC5f0cc622531dA21',
  '0x2f85F959d5F72c4db00B7357af647C17b68C2E83',
  '0x8afA39d3EBA8C62A29b8Dd040d96FcE83Ea849CF',
  '0xd27A2CaF566B95b9F6e0159CAa920c1d25919d7C',
  '0xceEf02B53407620c71F92b35B710382E89B029e2',
  '0xD6a02bbcc789BEcA35b72bdf1092AF64ce019193',
  '0x77d210e08Ec128EEB6a7705db5A3CBB246a000AC',
  '0x42b91684f09321dcf6b4410b5ea5a7ba9ac4b08e',
  '0xaa2e61Bd52D62cCCC988E3dd1E38FC10c25741E5',
  '0x7b207d27FF26069E48EA631518a2E784a1460cB0',
  '0x9C9E22B6d527a68279f8BdA25FdE4536341C3B8F',
  '0xC53C9CE817992b7679ccd355D25B172AE611D6c1',
  '0x1597b351f2390a8bFBdbFcF88179f3bDc5D2Ec82',
  '0x27A148DF8bCFaa6C48c67805EF39d382D14108AA',
  '0xc439e08a6bA59c6F0Dbe59E0dB657ba39bCF4Df7',
  '0x32Fe64800E1dc6351DD3b00B4Be0f73fc8d28e7D',
  '0x8882C9feFB5d4D829a8De694B0d017a4Fa92c927',
  '0x1Ee5A6eE786eB39655a6150d02Fd0d97963597D0',
  '0xca8B656B5032cA9dC43B0C160Cf1C5F5f218A658',
  '0x0e8F8f13b3EcC224a8CdF030580C4bb5478349c1',
  '0xB7e91eBc928543Fd9c396a11aeA76a0cf3dbe093',
  '0x6E61F385B20e9e98A48934e84d9226CBE2af42E8',
  '0x98CE5a348280d5df79E2441A9F9cbd2E73a8f40B',
  '0x728F1973c71f7567dE2a34Fa2838D4F0FB7f9765',
  '0x40495B5e21F4909346457c7dc83177Cd3617D5D9',
  '0xDf0c54cBe7B4f59f8dD5bAB213Db30bBE4de2988',
  '0x4B1f5193ea863586f570C43aB6967C45a5cE9557',
  '0xFa22B93f0FECE855A71F6D6f3b2100f0389EA5ee',
  '0xab6780431465df3c5357d62BCc66628c7fecD22b',
  '0x41d74A710ef67625B8caF0B5512e6926B1e19C2c',
  '0xf65616bd637D01Ea6D090C78832E4303862073e6',
  '0xb3D9A9123aCc804C2d5B29e24A4C9346b3f3e767',
  '0xB235e244aC2AFFa3D393E88F7bfD0ED771659bb8',
  '0x9b6e0d6F75B41d3D62053003f53fac8E804d1883',
  '0x2238eaE30C1DB5778E3f70Bf704901F81bd4738A',
  '0x4fFaA1B2Db0744d035f1A52815e243e02C15275F',
  '0x5bd0b1193fC353609f37eeE6b9F5454C2C3B6DaD',
  '0xB3f7B1EB5Fe7F1545983D218E217d3325cfa910f',
  '0x20C7e346a790DFD7e1766Ee20717173E524A5940',
  '0x47a077316E71B5A1da34D4b604388c3c8b379f4A',
  '0x0fD1561CD1C2EC40029751463f7a9c4FC94d70Ee',
  '0x96Cb84ac416602cec04B6778fa3F8e588e84cc95',
  '0x15864F6f1E801261Bba634f923b833D8894d81De',
  '0x37a6a37d724a85B151E9e74bdf030Ca35CD08A5a',
  '0x3DF48935312452fc4e70Dd8491D7b52ADCE1F4DE',
  '0x088424bCb69CcFD8Fc2DDa8c043dc4FeDb005928',
  '0xb281133a15f080185fF52006c9e4eA4Cc1c9bc44',
  '0xFf8a2Cb94831e4303A279c2a25e68256Beaf092A',
  '0xfd0018E87F75D90f66893592a7EF1a47348e37b6',
  '0x87618599569485038E48DF863A91AC704458B09F',
  '0x76689EC1706693aA232c54045Ee4cd9e8f130bDe',
  '0xf970342997df2e6ad92fc10be56048e678e3b4ac',
  '0xaFC71c187c4be7aae2EB3ED1A5c070d01f726456',
  '0xD1cd46934e9a22f9b4312fd7F2fe7061ca24ea52',
  '0x2Ae7554a872FCDfEe8A3D4D7630871dA8833ADD8',
  '0x7279A58d87C773621e1a71d08c31E5770FDbFaaE',
  '0xC9D2F9c0F8A303DFa8141011b9d04dAC08d10D89',
  '0x81C6a6f75F80cAa8BffAbe49f35cA8Bf7d19A501',
  '0xA1ABb72912a119630Cd0cF7199E47E4049759d01',
  '0xeC86555ca532975cc67a32D21FD04377110108B8',
  '0x5CB08a6D5a6DD153B22Ab8C856e56095aa31D440',
  '0x3635B3d38B971ED37b17E6E1Ac685Af87bc8d930',
  '0x8ad5ABa6216afaC5cf0E6f5a0eB01A23dC022f03',
  '0x010298F5dDE499b371A86d6ce7ee454b68B62780',
  '0xC0B426A0a8BE55D4A3fbBf702661480c0FBf050c',
  '0xc97baee84347477bbc75e52e830820116cda6b45',
  '0x236cE1b8e94dfe8280D802025E9D19Cdb9FFEe02',
  '0x6E1EA71Fd8d649d0696F62f4fcEEBc821CF7cc0b',
  '0x52b3f1f28a97a87dad50691c28aa777570df530a',
  '0x420491E77fe4f501D7Ae28477264179e3AD40Cc0',
  '0x0b5edBbc4bD2967fA72aF955447799499d6e96c0',
  '0xf67E79d304aC4F414Ceab27DC0A871BBe441Ff76',
  '0x64F13327CD8a7615612dA4D3E686877b549F4484',
  '0x6B5e8FA4A4399ad73985Da3B50a32522A81e2f00',
  '0x5e6fFF2F946af0fB0D48E9314194376D71730adE',
  '0x4E72d52aD1C80A35159E3227cb5B31c5811f3214',
  '0xb754Be27D4EAb18Dfdd4cFe42b538A80170721A7',
  '0x46E8314465811F528163D6e01724D39d800B4509',
  '0x91ddC94F43e376829E00B443165B434B3D25A0D4',
  '0xa65C1768400Fe2c8355aA58595bf09FBE1a69631',
  '0x2cf8f6648d2ce39fbb12a17c9ead04bc92d678a4',
  '0x6b57dB9a8b5de53B6Cc4827f2C71089529ea8407',
  '0x0a5465ae620F35435605d0a237E5fcb4A2Fd70c7',
  '0x7d9d2780e7628616E468b14d66dea6d62907Ab94',
  '0x8E453FA4a51104Ea037ac7e77d3d494547C0306a',
  '0x70A052732D3b0136064038a770EA604eF9c04a55',
  '0x1ff04F42aAa2A2b0A43bb8d72BF2DA2244599DA1',
  '0x40BAd26432912b416CD5F665b99812c5E9729834',
  '0x3F00ae99c4e918c73A3DBa9aAFb6ee372F89e1F3',
  '0x5b64ca9FE8bEE39b2f4fa44838BfC270ee6aE988',
  '0xA72159E85a034258446765D9279422A9fcda0F2F',
  '0xE5cEa7D7346BefBe0e34142FE98c5C957091b429',
  '0x4db6B14978FE1E4091526C23aa3E8d72eEA583F2',
  '0xc4ad69F0ff85874A8eF1397F407535597d81b6A2',
  '0x0EE53B2ec68731F3bfFAfc0c764092dE70Aa3434',
  '0x5F6d0F81a0a7D5CB02DbeCbfCabE7cAa04F093fc',
  '0xBaB5aFb94af15440a27106a0a616E858c8B28B77',
  '0xc950cc20b4a6afab770c2e4073d6f90f8981fa0f',
  '0xB5d149fEbB06D36996426633AeDD416b7D0ceBbD',
  '0xA83282Ef22CfCc0cb17855D29D6FB30C98c38927',
  '0xf5415e990dFd74f88a77B3FD119F692e4129EfE4',
  '0x16090EA0Ebee0bae3ed7D253cF43Ea1d63Ce079b',
  '0x8c9E69446c058192A028A338a8d928a96fcA8123',
  '0x8d43dF22045B83c2434dDEC005c22A17e8aDbdBe',
  '0xCAc6A5722abA8e7bd0B772b9A2aCF8830cEb19D9',
  '0x528f0830579aa74Ef8A0bDfB4daef583994b78cA',
  '0xd3E7c6fBf2Dcb17b97736649F6883C6F73cD0621',
  '0xDC52Ad5d7bcBcF2b3C3d38e353F1004FF61f32D2',
  '0x94f4614DF3e100C5Ed93A096015cB0cF5465bA43',
  '0xe8dF0E9E366734883396447E3B3c9a183Cd22dad',
  '0xB2407902F88dEA90A1382fc5b3E292F58417525E',
  '0x3826335E2bc15Ffa99Bf697c28352C7E871a228b',
  '0xcc5dc6ca37ecbb6cb68e795fafeda37904a24115',
  '0xc39fed6fA1301bD7205cAE82bb990440A4850B17',
  '0x0Cd07e86cf4807F3152A45B9A32BC3c9bFB44da1',
  '0xF7A4BEd6ffa24BFD4533A303CBB51e6671f035F0',
  '0x09f95bd58F8714D231809440a459e2675510fd4C',
  '0x5e9d0dC472bC30B25D8266cc22DbB5C6472a9Fb2',
  '0x1e4BfD1417205736DF6a1CB5ee840A6823ef4D0e',
  '0x709DE2F17AF168Df75d03205fF9be62102207750',
  '0x661973C8942A3C737AeB97C9B05530590fb6a6EF',
  '0xA122c1aEB39aD783d541479d3B7E34d18Ece55d6',
  '0x16e508404D588250cA339D2864Ad419b4d1Da460',
  '0x17e95670Bb6F89344E12f38E18c7184991Cd87f7',
  '0x3A91a0E7Bc63F57F8461269e976Dae04cBfBDda3',
  '0x63A4F913E06a678b1326C124c6Bfe16e5EBD29f8',
  '0x5aede59255e77ccfab97fde9e4e914921fb245fa',
  '0xF154048CD8efB1334Ab4b0226c1437B034a9e19b',
  '0x6d01800bb6b8ce1983f1ca2be2243b12688900ee',
  '0xc456d38afb6a48c15e7eeaac9b32c451846808a3',
  '0xcd741cb75cc34145b6437f5070906d5139b7fd54',
  '0xe8616eab82aa739e532ab72f75bebb8e3238c583',
  '0xb9c2cb57dfe51f8a2fb588f333bdc89d8d90ca9b',
  '0xb353b2117d00b457db995c4d2ba5db56ace50fd3',
  '0x5e7a56adfc8dd1e5da4686d7fd51d30efd47b8a8',
  '0xbf2c089f3e9d23aa7d124c4b4e8371a54300fd5e',
  '0x5e93802845f7e3e7427630c8d1459f4b0c35d8f1',
  '0x86f1f0e851ec46d3bad87572e1220c0fc7827799',
  '0x810c3243032f3ac57e9351812f116c3ee6953c18',
  '0x12c035fc321a312e11a171f1d1239623769399d7',
  '0x01d6f0b38e2814dd8ef0163bfc05492eb7ea9b23',
  '0x8b3f4eb783270aefaac9238ac1d165a433c8fbf3',
  '0x6bd217772e9d22164790ba13da860cf7e57203b0',
  '0x325c08a558ff02f244f31ba1e87bf3c1b1c08f45',
  '0x3cf65c8ee7316a92ab7d0ef793441a1419af13cc',
  '0x28d604a4205549ea2510d03f2addc673ded2187f',
  '0x00724872dF6dC47e40078aD80f7F9e7005b9eaa9',
  '0x84DC4343F50Ac947954bb1C4aCa7145d92B9C642',
  '0xB0AcF08a36dE52e8f3c4F975971973F20781Dba3',
  '0x812859c53cfeC1F7bb8419B59385B3B2ad48fa3D',
  '0x46A20E03f47ec67D2c8E73819C12a6b0c4BbD081',
  '0x6D2484B9c9E5961E90774ff0A6D51278Bd6031a8',
  '0xafCa6267D6C657c4F1c461E73ec9967365C3Be21',
  '0xC399818f7b9A7ADF6Aed972F7E8E11283B35be41',
  '0x3b4656Ad061f0C5978D297284ec9A08d7895bE3C',
  '0xd4F7D097D7Bcf3b23e7E3419e41110523B4d89a2',
  '0xB20f010824CA2873B3AB309fA30dB87eE602ad5B',
];

whiteListAddresses = whiteListAddresses.map(address =>
  ethers.utils.getAddress(address)
);

const leafNodes = whiteListAddresses.map(address => keccak256(address));
const merkleTree = new MerkleTree(leafNodes, keccak256, {
  sortPairs: true,
});

const rootHash = merkleTree.getRoot();
console.log(merkleTree.getHexRoot());
export const verifyMerkleProof = address => {
  let hashedAddress = keccak256(address);
  let proof = merkleTree.getHexProof(hashedAddress);
  return {
    proof: proof,
    allowedMint: merkleTree.verify(proof, hashedAddress, rootHash),
  };
};

export const getWhiteListAddresses = () => {
  return whiteListAddresses;
};
