import React, { useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { Link } from 'react-scroll';
import { Link as NormalLink } from 'react-router-dom';
import Login from './Login';
import { useLocation } from 'react-router-dom';

export default function SideBar(props) {
  // If the wallet is connected, all values will be set. Use to display the main nav below.
  const contractAvailable = !(
    !props.data.provider &&
    !props.data.accounts &&
    !props.data.contract
  );
  // Grab the connected wallet address, if available, to pass into the Login component
  const walletAddress = props.data.accounts ? props.data.accounts[0] : '';

  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 850;

  const location = useLocation();

  React.useEffect(() => {
    /* Inside of a "useEffect" hook add an event listener that updates
       the "width" state variable when the window size changes */
    window.addEventListener('resize', () => setWidth(window.innerWidth));

    /* passing an empty array as the dependencies of the effect will cause this
       effect to only run when the component mounts, and not each time it updates.
       We only want the listener to be added once */
  }, []);

  return width < breakpoint ? (
    <Menu className='ABC' {...props}>
      <NormalLink className='menu-item' to='/home'>
        HOME
      </NormalLink>
      <NormalLink className='menu-item' to='/mint'>
        MINT
      </NormalLink>

      <Login
        callback={props.callback}
        connected={contractAvailable}
        address={walletAddress}
      ></Login>
      <div className='social-media-header-burger'>
        <a href='https://twitter.com/parrotscaribnft'>
          <img src='/Twitter.svg' alt='' />
        </a>
        <a href='https://discord.gg/parrotsofthecaribbean'>
          <img src='/Discord.svg' alt='' />
        </a>
        <a href='https://opensea.io/collection/parrots-of-the-caribbean-genesis'>
          <img src='/opensea.svg' alt='' />
        </a>
      </div>
    </Menu>
  ) : (
    <></>
  );
}
